import {
    CREATE_PROPERTY,
    CREATE_PROPERTY_FAIL,
    CREATE_PROPERTY_SUCCESS,
    CLEAR_ERRORS,
    ALL_PROPERTY_REQUEST,
    ALL_PROPERTY_SUCCESS,
    ALL_PROPERTY_FAIL,
    CREATE_UNIT,
    CREATE_UNIT_FAIL,
    CREATE_UNIT_SUCCESS,
    ALL_UNIT_REQUEST, ALL_UNIT_FAIL, ALL_UNIT_SUCCESS,
    GET_PROPERTY_REQUEST,
    GET_PROPERTY_DETAILS_SUCCESS,
    MANAGERS_REQUEST,
    GET_MANAGERS_SUCCESS,
    GET_MANAGERS_FAILURE,
} from "../constants/Property";
import { ALL_MAINTENANCE_FAIL, ALL_MAINTENANCE_REQUEST, ALL_MAINTENANCE_SUCCESS } from "../constants/Maintenance";


export const allPropertyReducer = (state = {
    loading: false,
    loadingPropertyDetails: false,
    loadingManagers: false,
    managers: [],
    property: {},
    properties: [],
    units: [],
    loadingCreate: false,
    isCreated: false,
    isCreatedUnit: false,
    errorCreate: false,
    data: null,
    loadingGetProperty: false,
    loadingGetUnit: false,
    propertyData: null,
    unitData: null,
    errorGetProperty: false,
    errorGetUnit: false,
    loadingEdit: false,
    errorEdit: false,
    isUpdated: false,
}, action) => {
    switch (action.type) {
        case CREATE_PROPERTY:
            return {
                ...state,
                loadingCreate: true,
                isCreated: false,
                propertyData: null
            }

        case CREATE_PROPERTY_SUCCESS:
            return {
                loadingCreate: false,
                data: action.payload,
                propertyData: action.payload,
                isCreated: true
            }


        case CREATE_PROPERTY_FAIL:
            return {
                loadingCreate: false,
                errorCreate: action.payload,
                isCreated: false,
                propertyData: null
            }

        case CREATE_UNIT:
            return {
                ...state,
                loadingCreate: true,
                isCreated: false,
                isCreatedUnit: false
            }

        case CREATE_UNIT_SUCCESS:
            return {
                loadingCreate: false,
                data: action.payload,
                isCreated: true,
                isCreatedUnit: true,
            }


        case CREATE_UNIT_FAIL:
            return {
                loadingCreate: false,
                errorCreate: action.payload,
                isCreated: false,
                isCreatedUnit: false
            }


        case ALL_PROPERTY_REQUEST:
            return {
                loading: true
            }

        case ALL_PROPERTY_SUCCESS:
            return {
                loading: false,
                properties: action.payload.items,
                page: action.payload.page,
                totalPages: action.payload.totalPages,
                itemsTotalCount: action.payload.itemsTotalCount
            }

        case GET_PROPERTY_REQUEST:
            return {
                loadingPropertyDetails: true
            }

        case GET_PROPERTY_DETAILS_SUCCESS:
            console.log('chlra idhr')
            return {
                loadingPropertyDetails: false,
                property: action.payload.propertyData,
                managers: action.payload.managerData,
                // assignedSupervisor: action.payload.assignedSupervisor,
                // equipment: action.payload.equipment
            }

        case MANAGERS_REQUEST:
            return {
                loadingManagers: true
            }
        case GET_MANAGERS_SUCCESS:
            return {
                loadingManagers: false,
                managers: action.payload.managerData,
                // assignedSupervisor: action.payload.assignedSupervisor,
                // equipment: action.payload.equipment
            }

        case ALL_PROPERTY_FAIL:
            return {
                loading: false,
                error: action.payload
            }



        case ALL_UNIT_REQUEST:
            return {
                loading: true
            }

        case ALL_UNIT_SUCCESS:
            return {
                loading: false,
                units: action.payload.items,
                page: action.payload.page,
                totalPages: action.payload.totalPages,
                itemsTotalCount: action.payload.itemsTotalCount
            }

        case ALL_UNIT_FAIL:
            return {
                loading: false,
                error: action.payload
            }


        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}
