import React, { Fragment, lazy, Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import ProtectedRoute from "components/util-components/ProtectedRoute";
import APP_ROUTES from "constants/routes";

const modules = [
	{
		directory: "./organization/areas",
		path: {
			list: APP_ROUTES.ORG_AREAS_LIST,
			add: APP_ROUTES.ORG_AREAS_ADD,
			edit: APP_ROUTES.ORG_AREAS_EDIT,
		},
	},
	{
		directory: "./organization/services",
		path: {
			list: APP_ROUTES.ORG_SERVICES_LIST,
			add: APP_ROUTES.ORG_SERVICES_ADD,
			edit: APP_ROUTES.ORG_SERVICES_EDIT,
		},
	},
	{
		directory: "./organization/teams",
		path: {
			list: APP_ROUTES.ORG_TEAMS_LIST,
			add: APP_ROUTES.ORG_TEAMS_ADD,
			edit: APP_ROUTES.ORG_TEAMS_EDIT,
		},
	},
	{
		directory: "./users/supervisors",
		path: {
			list: APP_ROUTES.USERS_SUPERVISORS_LIST,
			add: APP_ROUTES.USERS_SUPERVISORS_ADD,
			edit: APP_ROUTES.USERS_SUPERVISORS_EDIT,
		},
	},
	{
		directory: "./users/technicians",
		path: {
			list: APP_ROUTES.USERS_TECHNICIANS_LIST,
			add: APP_ROUTES.USERS_TECHNICIANS_ADD,
			edit: APP_ROUTES.USERS_TECHNICIANS_EDIT,
		},
	},
	{
		directory: "./users/tenants",
		path: {
			list: APP_ROUTES.USERS_TENANTS_LIST,
			add: APP_ROUTES.USERS_TENANTS_ADD,
			edit: APP_ROUTES.USERS_TENANTS_EDIT,
		},
	},
];

export const AppViews = () => {
	return (
		<Suspense fallback={<Loading cover='content' />}>
			<Switch>
				{/* HOME */}
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/home`}
					component={lazy(() => import(`./home`))}
				/>
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/org/quick-add`}
					component={lazy(() => import("./organization/quick-add"))}
				/>

				{/* ------ Organization ------ */}
				<ProtectedRoute
					exact
					path={APP_ROUTES.ORG_AREAS_LIST}
					component={lazy(() => import("./organization/areas/List"))}
				/>
				<ProtectedRoute
					exact
					path={APP_ROUTES.ORG_AREAS_ADD}
					component={lazy(() => import("./organization/areas/AddEdit"))}
				/>
				<ProtectedRoute
					exact
					path={APP_ROUTES.ORG_AREAS_EDIT + "/:id"}
					component={lazy(() => import("./organization/areas/AddEdit"))}
				/>

				<ProtectedRoute
					exact
					path={APP_ROUTES.ORG_SERVICES_LIST}
					component={lazy(() => import("./organization/services/List"))}
				/>
				<ProtectedRoute
					exact
					path={APP_ROUTES.ORG_SERVICES_ADD}
					component={lazy(() => import("./organization/services/AddEdit"))}
				/>
				<ProtectedRoute
					exact
					path={APP_ROUTES.ORG_SERVICES_EDIT + "/:id"}
					component={lazy(() => import("./organization/services/AddEdit"))}
				/>

				<ProtectedRoute
					exact
					path={APP_ROUTES.ORG_TEAMS_LIST}
					component={lazy(() => import("./organization/teams/List"))}
				/>
				<ProtectedRoute
					exact
					path={APP_ROUTES.ORG_TEAMS_ADD}
					component={lazy(() => import("./organization/teams/AddEdit"))}
				/>
				<ProtectedRoute
					exact
					path={APP_ROUTES.ORG_TEAMS_EDIT + "/:id"}
					component={lazy(() => import("./organization/teams/AddEdit"))}
				/>

				{/* ------ Users ------ */}
				<ProtectedRoute
					exact
					path={APP_ROUTES.USERS_SUPERVISORS_LIST}
					component={lazy(() => import("./users/supervisors/List"))}
				/>
				<ProtectedRoute
					exact
					path={APP_ROUTES.USERS_SUPERVISORS_ADD}
					component={lazy(() => import("./users/supervisors/AddEdit"))}
				/>
				<ProtectedRoute
					exact
					path={APP_ROUTES.USERS_SUPERVISORS_EDIT + "/:id"}
					component={lazy(() => import("./users/supervisors/AddEdit"))}
				/>

				<ProtectedRoute
					exact
					path={APP_ROUTES.USERS_TENANTS_LIST}
					component={lazy(() => import("./users/tenants/List"))}
				/>
				<ProtectedRoute
					exact
					path={APP_ROUTES.USERS_TENANTS_ADD}
					component={lazy(() => import("./users/tenants/AddEdit"))}
				/>
				<ProtectedRoute
					exact
					path={APP_ROUTES.USERS_TENANTS_EDIT + "/:id"}
					component={lazy(() => import("./users/tenants/AddEdit"))}
				/>

				<ProtectedRoute
					exact
					path={APP_ROUTES.USERS_TECHNICIANS_LIST}
					component={lazy(() => import("./users/technicians/List"))}
				/>
				<ProtectedRoute
					exact
					path={APP_ROUTES.USERS_TECHNICIANS_LIST + "/profile/:id"}
					component={lazy(() => import("./users/technicians/Profile"))}
				/>
				<ProtectedRoute
					exact
					path={APP_ROUTES.USERS_TECHNICIANS_ADD}
					component={lazy(() => import("./users/technicians/AddEdit"))}
				/>
				<ProtectedRoute
					exact
					path={APP_ROUTES.USERS_TECHNICIANS_EDIT + "/:id"}
					component={lazy(() => import("./users/technicians/AddEdit"))}
				/>

				{/* EDIT PROFILE */}
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/me/update`}
					component={lazy(() => import(`./editProfile`))}
				/>

				{/* CHANGE PASSWORD */}
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/me/change-password`}
					component={lazy(() => import(`./changePassword`))}
				/>

				{/* INVENTORIES */}
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/inventories`}
					component={lazy(() => import(`./inventories`))}
				/>
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/inventories/create`}
					component={lazy(() => import(`./inventories/createInventory`))}
				/>
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/inventories/edit/:id`}
					component={lazy(() => import(`./inventories/createInventory`))}
				/>

				{/* ITEMS */}
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/items/:id`}
					component={lazy(() => import(`./items/index`))}
				/>
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/items/bulkcreate`}
					component={lazy(() => import(`./items/BulkItem`))}
				/>
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/items/create/:id`}
					component={lazy(() => import(`./items/CreateItem`))}
				/>

				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/items/:inventoryId/edit/:id`}
					component={lazy(() => import(`./items/CreateItem`))}
				/>

				{/* AREAS */}
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/areas`}
					component={lazy(() => import(`./areas`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/areas/create`}
					component={lazy(() => import(`./areas/create`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/areas/update/:id`}
					component={lazy(() => import(`./areas/update`))}
				/>

				{/* SERVICES */}
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/services`}
					component={lazy(() => import(`./services`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/services/create`}
					component={lazy(() => import(`./services/create`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/services/update/:id`}
					component={lazy(() => import(`./services/update`))}
				/>

				{/* TENANTS */}
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/tenants`}
					component={lazy(() => import(`./tenants`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/tenants/create`}
					component={lazy(() => import(`./tenants/create`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/tenants/update/:id`}
					component={lazy(() => import(`./tenants/update`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/tenants/:id`}
					component={lazy(() => import(`./tenants/details`))}
				/>

				{/* SUPERVISORS */}
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/supervisors`}
					component={lazy(() => import(`./supervisors`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/supervisors/create`}
					component={lazy(() => import(`./supervisors/createSupervisor`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/supervisors/update/:id`}
					component={lazy(() => import(`./supervisors/updateSupervisor`))}
				/>

				{/* TEAMS */}
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/teams`}
					component={lazy(() => import(`./teams`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/teams/create`}
					component={lazy(() => import(`./teams/create`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/teams/update/:id`}
					component={lazy(() => import(`./teams/update`))}
				/>

				{/* TEAM LEAD */}
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/teamlead`}
					component={lazy(() => import(`./teamlead`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/teamlead/create`}
					component={lazy(() => import(`./teamlead/create`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/teamlead/update/:id`}
					component={lazy(() => import(`./teamlead/update`))}
				/>

				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/settings`}
					component={lazy(() => import(`./settings`))}
				/>

				{/* MAINTENANCE */}
				<ProtectedRoute
					exact
					path={`${APP_PREFIX_PATH}/maintenance/corrective`}
					component={lazy(() => import(`./maintenance/corrective-maintenance`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/maintenance/corrective/add-corrective-task`}
					component={lazy(() =>
						import(`./maintenance/corrective-maintenance/add-corrective-task`)
					)}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/maintenance/corrective/edit-corrective-task/:id`}
					component={lazy(() =>
						import(`./maintenance/corrective-maintenance/edit-corrective-task`)
					)}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/maintenance/preventive/add-preventive-task`}
					component={lazy(() =>
						import(`./maintenance/preventive-maintenance/add-preventive-task`)
					)}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/maintenance/preventive/edit-preventive-task/:id`}
					component={lazy(() =>
						import(`./maintenance/preventive-maintenance/edit-preventive-task`)
					)}
				/>

				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/maintenance/preventive`}
					component={lazy(() => import(`./maintenance/preventive-maintenance`))}
				/>

				{/*PROPERTIES & PROJECTS*/}

				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/add/create`}
					component={lazy(() => import(`./projects/add/create`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/list`}
					component={lazy(() => import(`./projects`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/dashboard`}
					component={lazy(() => import(`./projects/dashboard`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/property/list`}
					component={lazy(() => import(`./projects/property/list`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/unit/list`}
					component={lazy(() => import(`./projects/unit/list`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/property/add`}
					component={lazy(() => import(`./projects/property/add`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/unit/add`}
					component={lazy(() => import(`./projects/unit/add`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/view/:projectId`}
					component={lazy(() => import(`./projects/view`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/view-details/:propertyId`}
					component={lazy(() => import(`./projects/property/view-detail`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/edit/:projectId`}
					component={lazy(() => import(`./projects/edit`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/addProperty/:projectId`}
					component={lazy(() => import(`./projects/addProperty`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/property/add-equipment/:flag/:id`}
					component={lazy(() =>
						import(`./projects/property/view-detail/equipment-details/create`)
					)}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/property/add-unit/:propertyId`}
					component={lazy(() =>
						import(`./projects/property/view-detail/units/add`)
					)}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/property/edit-unit/:unitId`}
					component={lazy(() =>
						import(`./projects/property/view-detail/units/edit`)
					)}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/property/unit/:unitId`}
					component={lazy(() =>
						import(`./projects/property/view-detail/units/view`)
					)}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/leasing/create/:unitId`}
					component={lazy(() =>
						import(`./projects/leasing/create`)
					)}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/booking/create/:unitId`}
					component={lazy(() =>
						import(`./projects/booking/create`)
					)}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/projects/property/maintenanace-request`}
					component={lazy(() =>
						import(`./projects/property/view-detail/equipment-details/create-maintenanace-request`)
					)}
				/>

				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/home/statistics`}
					component={lazy(() => import(`./statistics`))}
				/>
				<ProtectedRoute
					path={`${APP_PREFIX_PATH}/home/statistics`}
					component={lazy(() => import(`./statistics`))}
				/>
				<Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
			</Switch>
		</Suspense>
	);
};

export default React.memo(AppViews);
