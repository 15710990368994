import {APP_PREFIX_PATH} from 'configs/AppConfig';

/**
 * @enum {string}
 */
const routes = {
  ORG_AREAS_LIST: `${APP_PREFIX_PATH}/org/areas`,
  ORG_AREAS_ADD: `${APP_PREFIX_PATH}/org/areas/add`,
  ORG_AREAS_EDIT: `${APP_PREFIX_PATH}/org/areas/edit`,

  ORG_SERVICES_LIST: `${APP_PREFIX_PATH}/org/services`,
  ORG_SERVICES_ADD: `${APP_PREFIX_PATH}/org/services/add`,
  ORG_SERVICES_EDIT: `${APP_PREFIX_PATH}/org/services/edit`,

  ORG_TEAMS_LIST: `${APP_PREFIX_PATH}/org/teams`,
  ORG_TEAMS_ADD: `${APP_PREFIX_PATH}/org/teams/add`,
  ORG_TEAMS_EDIT: `${APP_PREFIX_PATH}/org/teams/edit`,

  USERS_SUPERVISORS_LIST: `${APP_PREFIX_PATH}/users/supervisors`,
  USERS_SUPERVISORS_ADD: `${APP_PREFIX_PATH}/users/supervisors/add`,
  USERS_SUPERVISORS_EDIT: `${APP_PREFIX_PATH}/users/supervisors/edit`,

  USERS_TENANTS_LIST: `${APP_PREFIX_PATH}/users/tenants`,
  USERS_TENANTS_ADD: `${APP_PREFIX_PATH}/users/tenants/add`,
  USERS_TENANTS_EDIT: `${APP_PREFIX_PATH}/users/tenants/edit`,

  USERS_TECHNICIANS_LIST: `${APP_PREFIX_PATH}/users/technicians`,
  USERS_TECHNICIANS_ADD: `${APP_PREFIX_PATH}/users/technicians/add`,
  USERS_TECHNICIANS_EDIT: `${APP_PREFIX_PATH}/users/technicians/edit`,
};

export default routes;
