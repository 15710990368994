export const COMPOUND_STATISTICS_REQUEST = 'COMPOUND_STATISTICS_REQUEST'
export const COMPOUND_STATISTICS_SUCCESS = 'COMPOUND_STATISTICS_SUCCESS'
export const COMPOUND_STATISTICS_FAIL = 'COMPOUND_STATISTICS_FAIL'

export const TICKETS_STATISTICS_REQUEST = 'TICKETS_STATISTICS_REQUEST'
export const TICKETS_STATISTICS_SUCCESS = 'TICKETS_STATISTICS_SUCCESS'
export const TICKETS_STATISTICS_FAIL = 'TICKETS_STATISTICS_FAIL'

export const TICKETS_COUNT_DOM_REQUEST = 'TICKETS_COUNT_DOM_REQUEST'
export const TICKETS_COUNT_DOM_SUCCESS = 'TICKETS_COUNT_DOM_SUCCESS'
export const TICKETS_COUNT_DOM_FAIL = 'TICKETS_COUNT_DOM_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'