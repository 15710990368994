// export const INVENTORIES_LISTING_REQUEST = "INVENTORIES_LISTING_REQUEST";
// export const ALL_INVENTORIES_SUCCESS = "ALL_INVENTORIES_SUCCESS";
// export const ALL_INVENTORIES_FAIL = "ALL_INVENTORIES_FAIL";

export const CREATE_INVENTORY_REQUEST = "CREATE_INVENTORY_REQUEST";
export const CREATE_INVENTORY_SUCCESS = "CREATE_INVENTORY_SUCCESS";
export const CREATE_INVENTORY_RESET = "CREATE_INVENTORY_RESET";
export const CREATE_INVENTORY_FAIL = "CREATE_INVENTORY_FAIL";
// export const CREATE_CLEAR_ERRORS = "CREATE_CLEAR_ERRORS";
// export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const INVENTORY_REQUEST = "INVENTORY_REQUEST";
export const INVENTORY_SUCCESS = "INVENTORY_SUCCESS";
export const INVENTORY_FAIL = "INVENTORY_FAIL";
export const INVENTORY_CLEAR_ERRORS = "INVENTORY_CLEAR_ERRORS";
export const INVENTORY_DETAIL_SUCCESS = "INVENTORY_DETAIL_SUCCESS";
export const INVENTORY_DELETE_SUCCESS = "INVENTORY_DELETE_SUCCESS";

export const DELETE_INVENTORY_REQUEST = 'DELETE_INVENTORY_REQUEST'
export const DELETE_INVENTORY_SUCCESS = 'DELETE_INVENTORY_SUCCESS'
export const DELETE_INVENTORY_RESET = 'DELETE_INVENTORY_RESET'
export const DELETE_INVENTORY_FAIL = 'DELETE_INVENTORY_FAIL'
