import {
  TICKETS_STATS_FAILURE,
  TICKETS_STATS_REQUEST,
  TICKETS_STATS_SUCCESS
} from "../constants/Tickets";

const INITIAL_STATE = {
  loading: false,
  error: null,
  stats: null
};

export const registerTicketsStats = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TICKETS_STATS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        stats: null
      };
    case TICKETS_STATS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TICKETS_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        stats: action.payload
      };
    default:
      return state;
  }
};
