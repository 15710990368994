export const ALL_MAINTENANCE_REQUEST = 'ALL_MAINTENANCE_REQUEST'
export const ALL_MAINTENANCE_SUCCESS = 'ALL_MAINTENANCE_SUCCESS'
export const ALL_MAINTENANCE_FAIL = 'ALL_MAINTENANCE_FAIL'

export const CREATE_CORRECTIVE_MAINTENANCE = "CREATE_CORRECTIVE_MAINTENANCE"
export const CREATE_CORRECTIVE_MAINTENANCE_SUCCESS = "CREATE_CORRECTIVE_MAINTENANCE_SUCCESS"
export const CREATE_CORRECTIVE_MAINTENANCE_FAIL = "CREATE_CORRECTIVE_MAINTENANCE_FAIL"


export const CREATE_PREVENTIVE_MAINTENANCE = "CREATE_PREVENTIVE_MAINTENANCE"
export const CREATE_PREVENTIVE_MAINTENANCE_SUCCESS = "CREATE_PREVENTIVE_MAINTENANCE_SUCCESS"
export const CREATE_PREVENTIVE_MAINTENANCE_FAIL = "CREATE_PREVENTIVE_MAINTENANCE_FAIL"


export const EDIT_CORRECTIVE_MAINTENANCE = "EDIT_CORRECTIVE_MAINTENANCE"
export const EDIT_CORRECTIVE_MAINTENANCE_SUCCESS = "EDIT_CORRECTIVE_MAINTENANCE_SUCCESS"
export const EDIT_CORRECTIVE_MAINTENANCE_FAIL = "EDIT_CORRECTIVE_MAINTENANCE_FAIL"


export const EDIT_PREVENTIVE_MAINTENANCE = "EDIT_PREVENTIVE_MAINTENANCE"
export const EDIT_PREVENTIVE_MAINTENANCE_SUCCESS = "EDIT_PREVENTIVE_MAINTENANCE_SUCCESS"
export const EDIT_PREVENTIVE_MAINTENANCE_FAIL = "EDIT_PREVENTIVE_MAINTENANCE_FAIL"


export const GET_CORRECTIVE_MAINTENANCE = "GET_CORRECTIVE_MAINTENANCE"
export const GET_CORRECTIVE_MAINTENANCE_SUCCESS = "GET_CORRECTIVE_MAINTENANCE_SUCCESS"
export const GET_CORRECTIVE_MAINTENANCE_FAIL = "GET_CORRECTIVE_MAINTENANCE_FAIL"


export const GET_PREVENTIVE_MAINTENANCE = "GET_PREVENTIVE_MAINTENANCE"
export const GET_PREVENTIVE_MAINTENANCE_SUCCESS = "GET_PREVENTIVE_MAINTENANCE_SUCCESS"
export const GET_PREVENTIVE_MAINTENANCE_FAIL = "GET_PREVENTIVE_MAINTENANCE_FAIL"



export const CLEAR_ERRORS = 'CLEAR_ERRORS'

