export const ALL_TEAMLEADS_REQUEST = 'ALL_TEAMLEADS_REQUEST'
export const ALL_TEAMLEADS_SUCCESS = 'ALL_TEAMLEADS_SUCCESS'
export const ALL_TEAMLEADS_FAIL = 'ALL_TEAMLEADS_FAIL'

export const CREATE_TEAMLEAD_REQUEST = 'CREATE_TEAMLEAD_REQUEST'
export const CREATE_TEAMLEAD_SUCCESS = 'CREATE_TEAMLEAD_SUCCESS'
export const CREATE_TEAMLEAD_RESET = 'CREATE_TEAMLEAD_RESET'
export const CREATE_TEAMLEAD_FAIL = 'CREATE_TEAMLEAD_FAIL'

export const UPDATE_TEAMLEAD_REQUEST = 'UPDATE_TEAMLEAD_REQUEST'
export const UPDATE_TEAMLEAD_SUCCESS = 'UPDATE_TEAMLEAD_SUCCESS'
export const UPDATE_TEAMLEAD_RESET = 'UPDATE_TEAMLEAD_RESET'
export const UPDATE_TEAMLEAD_FAIL = 'UPDATE_TEAMLEAD_FAIL'

export const DELETE_TEAMLEAD_REQUEST = 'DELETE_TEAMLEAD_REQUEST'
export const DELETE_TEAMLEAD_SUCCESS = 'DELETE_TEAMLEAD_SUCCESS'
export const DELETE_TEAMLEAD_RESET = 'DELETE_TEAMLEAD_RESET'
export const DELETE_TEAMLEAD_FAIL = 'DELETE_TEAMLEAD_FAIL'

export const TEAMLEAD_DETAILS_REQUEST = 'TEAMLEAD_DETAILS_REQUEST'
export const TEAMLEAD_DETAILS_SUCCESS = 'TEAMLEAD_DETAILS_SUCCESS'
export const TEAMLEAD_DETAILS_FAIL = 'TEAMLEAD_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'