export const ALL_SUPERVISORS_REQUEST = 'ALL_SUPERVISORS_REQUEST'
export const ALL_SUPERVISORS_SUCCESS = 'ALL_SUPERVISORS_SUCCESS'
export const ALL_SUPERVISORS_FAIL = 'ALL_SUPERVISORS_FAIL'

export const CREATE_SUPERVISOR_REQUEST = 'CREATE_SUPERVISOR_REQUEST'
export const CREATE_SUPERVISOR_SUCCESS = 'CREATE_SUPERVISOR_SUCCESS'
export const CREATE_SUPERVISOR_RESET = 'CREATE_SUPERVISOR_RESET'
export const CREATE_SUPERVISOR_FAIL = 'CREATE_SUPERVISOR_FAIL'

export const UPDATE_SUPERVISOR_REQUEST = 'UPDATE_SUPERVISOR_REQUEST'
export const UPDATE_SUPERVISOR_SUCCESS = 'UPDATE_SUPERVISOR_SUCCESS'
export const UPDATE_SUPERVISOR_RESET = 'UPDATE_SUPERVISOR_RESET'
export const UPDATE_SUPERVISOR_FAIL = 'UPDATE_SUPERVISOR_FAIL'

export const DELETE_SUPERVISOR_REQUEST = 'DELETE_SUPERVISOR_REQUEST'
export const DELETE_SUPERVISOR_SUCCESS = 'DELETE_SUPERVISOR_SUCCESS'
export const DELETE_SUPERVISOR_RESET = 'DELETE_SUPERVISOR_RESET'
export const DELETE_SUPERVISOR_FAIL = 'DELETE_SUPERVISOR_FAIL'

export const SUPERVISOR_DETAILS_REQUEST = 'SUPERVISOR_DETAILS_REQUEST'
export const SUPERVISOR_DETAILS_RESET = 'SUPERVISOR_DETAILS_RESET'
export const SUPERVISOR_DETAILS_SUCCESS = 'SUPERVISOR_DETAILS_SUCCESS'
export const SUPERVISOR_DETAILS_FAIL = 'SUPERVISOR_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
