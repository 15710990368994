
export const ALL_PROPERTY_REQUEST = 'ALL_PROPERTY_REQUEST'
export const ALL_PROPERTY_SUCCESS = 'ALL_PROPERTY_SUCCESS'
export const ALL_PROPERTY_FAIL = 'ALL_PROPERTY_FAIL'

export const ALL_UNIT_REQUEST = 'ALL_UNIT_REQUEST'
export const ALL_UNIT_SUCCESS = 'ALL_UNIT_SUCCESS'
export const ALL_UNIT_FAIL = 'ALL_UNIT_FAIL'

export const CREATE_PROPERTY = "CREATE_PROPERTY_MAINTENANCE"
export const CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY_SUCCESS"
export const CREATE_PROPERTY_FAIL = "CREATE_PROPERTY_FAIL"

export const CREATE_UNIT = "CREATE_UNIT_MAINTENANCE"
export const CREATE_UNIT_SUCCESS = "CREATE_UNIT_SUCCESS"
export const CREATE_UNIT_FAIL = "CREATE_UNIT_FAIL"

export const GET_PROPERTY_REQUEST = 'GET_PROPERTY_REQUEST'
export const GET_PROPERTY_DETAILS_SUCCESS = 'GET_PROPERTY_DETAILS_SUCCESS'
export const GET_PROPERTY_DETAILS_FAILURE = 'GET_PROPERTY_DETAILS_FAILURE'

//for project managers in property
export const MANAGERS_REQUEST = 'MANAGERS_REQUEST'
export const GET_MANAGERS_SUCCESS = 'GET_MANAGERS_SUCCESS'
export const GET_MANAGERS_FAILURE = 'GET_MANAGERS_FAILURE'


export const CLEAR_ERRORS = 'CLEAR_ERRORS'

