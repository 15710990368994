import { combineReducers } from 'redux';
import Theme from './Theme';

// AUTH
import {
    ownerAuthReducer
} from './Auth';

// PROFILE
import {
    profileDataReducer,
    updateMeReducer, updateOTPReducer
} from './Profile'

// AREAS
import {
    allAreasReducer,
    registerAreaReducer,
    areaDetailsReducer,
    areaReducer
} from './Areas'

// SERVICES
import {
    allServicesReducer,
    registerServiceReducer,
    serviceDetailsReducer,
    servicesReducer,
    registerServicesSummary
} from './Services'

// TENANTS
import {
    allTenantsReducer,
    registerTenantReducer,
    tenantDetailsReducer,
    tenantReducer
} from './Tenants'

// SUPERVISOR
import {
    allSupervisorsReducer,
    registerSupervisorReducer,
    supervisorDetailsReducer,
    SupervisorReducer
} from './Supervisor'

// TEAMLEAD
import {
    allTeamleadsReducer,
    registerTeamLeadReducer,
    teamLeadDetailsReducer,
    teamleadReducer
} from './TeamLead'

// TECHNICIANS
import {
    allTechniciansReducer,
    registerTechnicianReducer,
    technicianDetailsReducer,
    technicianReducer
} from './Technicians'

// TEAMS
import {
    allTeamsReducer,
    registerTeamReducer,
    teamDetailsReducer,
    teamReducer
} from './Teams'

// STATISTICS
import {
    compoundStatisticsReducer,
    getDomHistogramDataReducer
} from './Statistics'


import {
    allMaintenanceReducer
} from './Maintenance'
import {
    allLookupsReducer
} from "./Lookups";
import {
    inventoryReducer
} from "./Inventory"
import {
    allItemReducer
} from "./Item"

import { supplierReducer } from "./Supplier"
import { currencyReducer } from "./Currency"
import { brandReducer } from "./Brand"
import { categoryReducer } from "./Category"
import { statusReducer } from "./Status"
import {allPropertyReducer} from "./Property";
import { allUnitReducer } from './Units';

import { registerTicketsStats } from "./Tickets";
import {allProjectsReducer} from "./Projects";
import project from './Project';

const reducers = combineReducers({
    theme: Theme,

    ownerAuth: ownerAuthReducer,

    profileData: profileDataReducer,
    updateMe: updateMeReducer,
    otp: updateOTPReducer,

    allAreas: allAreasReducer,
    registerArea: registerAreaReducer,
    areaDetails: areaDetailsReducer,
    area: areaReducer,

    servicesSummary: registerServicesSummary,
    allServices: allServicesReducer,
    registerService: registerServiceReducer,
    serviceDetails: serviceDetailsReducer,
    services: servicesReducer,

    allTenants: allTenantsReducer,
    registerTenant: registerTenantReducer,
    tenantDetails: tenantDetailsReducer,
    tenant: tenantReducer,

    allSupervisors: allSupervisorsReducer,
    registerSupervisor: registerSupervisorReducer,
    supervisorDetails: supervisorDetailsReducer,
    Supervisor: SupervisorReducer,

    allTeamleads: allTeamleadsReducer,
    registerTeamLead: registerTeamLeadReducer,
    teamLeadDetails: teamLeadDetailsReducer,
    teamlead: teamleadReducer,

    allTechnicians: allTechniciansReducer,
    registerTechnician: registerTechnicianReducer,
    technicianDetails: technicianDetailsReducer,
    technician: technicianReducer,

    allTeams: allTeamsReducer,
    registerTeam: registerTeamReducer,
    teamDetails: teamDetailsReducer,
    team: teamReducer,

    compoundStatistics: compoundStatisticsReducer,
    getDomHistogramData: getDomHistogramDataReducer,

    lookups: allLookupsReducer,

    inventory: inventoryReducer,
    item: allItemReducer,
    supplier: supplierReducer,
    currency: currencyReducer,
    brand: brandReducer,
    status: statusReducer,
    category: categoryReducer,
    maintenance: allMaintenanceReducer,
    property: allPropertyReducer,
    projects: allProjectsReducer,
    units: allUnitReducer,
    ticketsStats: registerTicketsStats,
    project: project,
});

export default reducers;
