import { Col, Row } from 'antd';
import { BellFilled, MessageFilled } from '@ant-design/icons';

export default function NavTools() {
  return (
    <Row gutter={16} align="middle" style={{ height: "100%" }}>
      <Col>
        <div className="navtools__icon-wrapper">
          <MessageFilled style={{ color: "#28a5dd", fontSize: "1rem" }} />
        </div>
      </Col>
      <Col>
        <div className="navtools__icon-wrapper">
          <BellFilled style={{ color: "#28a5dd", fontSize: "1rem" }} />
        </div>
      </Col>
    </Row>
  );
}
