import {
    ALL_ITEMS_FAIL,
    ALL_ITEMS_SUCCESS,
    ALL_ITEMS_REQUEST,
    ITEM_DETAIL_SUCCESS,
    CLEAR_ERRORS,
    ITEM_DELETE_RESET
  } from "../constants/Item";
  
  export const allItemReducer = (state = { item: [] }, action) => {
    switch (action.type) {
      case ALL_ITEMS_REQUEST:
        return {
          loading: true,
        };
  
      case ALL_ITEMS_SUCCESS:
        let itemTableList = []
        if(action.payload.items?.length > 0){
          action.payload.items?.forEach(data => {
            itemTableList.push({
                // check: <Checkbox />,
                _id:data?._id,
                name: data?.name,
                category: data?.category,
                brand: data?.brand,
                purchasedDate: data?.itemPurchaseDate,
                state: data?.status,
                maintenceRequired: data?.nextMaintenanceDate !== "" ? "yes": "No",
                ...data
            })
          })
        }
        return {
          loading: false,
          item: action.payload.items,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          itemsTotalCount: action.payload.itemsTotalCount,
          itemTableList:itemTableList
        };
  
      case ALL_ITEMS_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case ITEM_DETAIL_SUCCESS:
        return {
          ...state,
          details: action.payload,
          loading: false,
          error: null,
        };

      case ITEM_DELETE_RESET:
        return{
          ...state,
        loading: false,
        }
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  