import {
    ALL_PROJECT_REQUEST,
    ALL_PROJECT_SUCCESS,
    ALL_PROJECT_FAIL,
    CLEAR_ERRORS,
    DELETE_PROJECT_REQUEST,
    DELETE_PROJECT_SUCCESS,
    DELETE_PROJECT_FAIL 
} from "../constants/Project";


export const allProjectsReducer = (state = {
    loading: false,
    projects: [],
    properties: [],
    units: [],
    loadingCreate: false,
    loadingDelete: false,
    isCreated: false,
    isDeleted: false,
    errorCreate: false,
    data: null,
    loadingGetProperty: false,
    loadingGetUnit: false,
    propertyData: null,
    unitData: null,
    errorGetProperty: false,
    errorGetUnit: false,
    loadingEdit: false,
    errorEdit: false,
    isUpdated: false,
}, action) => {
    switch (action.type) {

        case ALL_PROJECT_REQUEST:
            return {
                loading: true
            }

        case ALL_PROJECT_SUCCESS:
            return {
                loading: false,
                projects: action.payload.items,
                page: action.payload.page,
                totalPages: action.payload.totalPages,
                itemsTotalCount: action.payload.itemsTotalCount
            }

        case ALL_PROJECT_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case DELETE_PROJECT_REQUEST:
            return {
                loadingDelete: true,
                isDeleted: false
            }
    
        case DELETE_PROJECT_SUCCESS:
            return {
                loadingDelete: false,
                isDeleted: true,
                message: action.payload.message
            }

        case DELETE_PROJECT_FAIL:
            return {
                loadingDelete: false,
                isDeleted: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}
