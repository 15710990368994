import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Avatar, message, Row, Col } from "antd";
import { DownOutlined, EditOutlined, EditFilled, LockFilled, LogoutOutlined, PlayCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux'

import Icon from 'components/util-components/Icon';
import { logout } from '../../redux/actions/Auth';
import { getProfileData } from "redux/actions/Profile";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';

const menuItem = [
	{
    title: "Edit Profile",
    icon: EditOutlined ,
    path: `${APP_PREFIX_PATH}/me/update`
  },
  {
    title: "Change Password",
    icon: EditOutlined ,
    path: `${APP_PREFIX_PATH}/me/change-password`
  }
]

export default function NavProfile() {
  // let history = useHistory();
  const dispatch = useDispatch()

  const { user } = useSelector(state => state.profileData)

  const logoutHandler = () => {
    dispatch(logout())
    localStorage.removeItem("HaressOwnerjwtToken");
    message.success('Logged out successfully.');
  }
  useEffect(() => {
    dispatch(getProfileData())
  }, [dispatch])

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      {user && (
        <div className="nav-profile-header">
          <div className="d-flex">
            <Avatar size={45} src={user.profilePicturePath} />
            <div className="pl-3">
              <h4 className="mb-0">{user.fullName}</h4>
              <span className="text-muted">{user.userTitle}</span>
            </div>
          </div>
        </div>
      )}
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <Link to={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={logoutHandler}>
            <a href={`${AUTH_PREFIX_PATH}/auth`}>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </a>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  const items = [
    {
      key: 1,
      icon: <EditFilled className="mx-2" style={{ color: "#28a5dd" }} />,
      label: <div className="mx-2 ml-4">Edit profile</div>
    },
    {
      key: 2,
      icon: <LockFilled className="mx-2" style={{ color: "#28a5dd" }} />,
      label: <div className="mx-2 ml-4">Change password</div>
    },
    {
      key: 3,
      icon: <PlayCircleFilled className="mx-2" style={{ color: "#28a5dd" }} />,
      label: (
        <div onClick={logoutHandler} className="mx-2 ml-4">
          <a className="text-decoration-none text-reset" href={`${AUTH_PREFIX_PATH}/auth`}>Sign out</a>
        </div>
      )
    }
  ];

  return (
    <Dropdown placement="bottomRight" menu={{items}}>
      <a onClick={(e) => e.preventDefault()}>
        {
          user && (
            <>
              <div className="nav-profile__dropdown-wrapper">
                <Row justify="center" align="middle" gutter={16}>
                  <Col>
                    <div className="nav-profile__user-name">{user.fullName}</div>
                    <div className="text-muted">{user.email}</div>
                  </Col>
                  <Col>
                    <DownOutlined />
                  </Col>
                </Row>
              </div>
            </>
          )
        }
      </a>
    </Dropdown>
  );
}
