import {
  INVENTORY_REQUEST,
  INVENTORY_SUCCESS,
  INVENTORY_FAIL,
  INVENTORY_CLEAR_ERRORS,
  INVENTORY_DETAIL_SUCCESS,
  INVENTORY_DELETE_SUCCESS,
  DELETE_INVENTORY_FAIL,
  DELETE_INVENTORY_REQUEST,
  DELETE_INVENTORY_SUCCESS,
  DELETE_INVENTORY_RESET,
} from "../constants/Inventory";

const initialState = {
  loading: false,
  data: null,
  details: null,
  error: null,
};

export const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case INVENTORY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };

    case INVENTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case INVENTORY_DETAIL_SUCCESS:
      return {
        ...state,
        details: action.payload,
        loading: false,
        error: null,
      };

    case DELETE_INVENTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DELETE_INVENTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: true,
      };

    case DELETE_INVENTORY_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false,
      };

    case DELETE_INVENTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // case INVENTORY_DELETE_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: null,
    //   };

    case INVENTORY_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
