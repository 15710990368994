import React from 'react'
import { Switch, Route, } from "react-router-dom";
import ClientViews from "../../views/client-views";

export const ClientLayout = () => {
	return (
		<div className="client-container">
			<Switch>
				<Route path="" component={ClientViews} />
			</Switch>
		</div>
	)
}


export default ClientLayout
