import { GET_UNIT_DETAILS } from "../constants/Units";

export const allUnitReducer = (
  state = {
    loading: false,
    unit: {},
    units: [],
    loadingCreate: false,
    isCreated: false,
    isCreatedUnit: false,
    errorCreate: false,
    data: null,
    loadingGetUnit: false,
    unitData: null,
    errorGetUnit: false,
    loadingEdit: false,
    errorEdit: false,
    isUpdated: false,
  },
  action
) => {
  switch (action.type) {
    case GET_UNIT_DETAILS:
      console.log("Reducer received payload:", action.payload);
      return {
        ...state,
        loadingGetUnit: false,
        unitData: action.payload.data, // Fix: Update unitData with fetched data
      };
    default:
      return state;
  }
};
