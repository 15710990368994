import {
  STATUS_REQUEST,
  STATUS_SUCCESS,
  STATUS_FAIL,
  STATUS_CLEAR_ERRORS,
} from "../constants/Status";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case STATUS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case STATUS_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
