export const ALL_AREAS_REQUEST = 'ALL_AREAS_REQUEST'
export const ALL_AREAS_SUCCESS = 'ALL_AREAS_SUCCESS'
export const ALL_AREAS_FAIL = 'ALL_AREAS_FAIL'

export const CREATE_AREA_REQUEST = 'CREATE_AREA_REQUEST'
export const CREATE_AREA_SUCCESS = 'CREATE_AREA_SUCCESS'
export const CREATE_AREA_RESET = 'CREATE_AREA_RESET'
export const CREATE_AREA_FAIL = 'CREATE_AREA_FAIL'

export const UPDATE_AREA_REQUEST = 'UPDATE_AREA_REQUEST'
export const UPDATE_AREA_SUCCESS = 'UPDATE_AREA_SUCCESS'
export const UPDATE_AREA_RESET = 'UPDATE_AREA_RESET'
export const UPDATE_AREA_FAIL = 'UPDATE_AREA_FAIL'

export const DELETE_AREA_REQUEST = 'DELETE_AREA_REQUEST'
export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS'
export const DELETE_AREA_RESET = 'DELETE_AREA_RESET'
export const DELETE_AREA_FAIL = 'DELETE_AREA_FAIL'

export const AREA_DETAILS_REQUEST = 'AREA_DETAILS_REQUEST'
export const AREA_DETAILS_SUCCESS = 'AREA_DETAILS_SUCCESS'
export const AREA_DETAILS_FAIL = 'AREA_DETAILS_FAIL'
export const AREA_DETAILS_RESET = 'AREA_DETAILS_RESET'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'