import React from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils'
import { onMobileNavToggle } from "redux/actions/Theme";


import propertyImage from "../../assets/svg/office-building.svg";
import maintenance from "../../assets/svg/progress-wrench.png";
import home from "../../assets/svg/home.svg";
import barn from "../../assets/svg/barn.svg";
import users from "../../assets/svg/users.svg";
import org from "../../assets/svg/org.svg";
import facilities from "../../assets/svg/facilities.svg";
import bell from "../../assets/svg/bell.svg";
import reports from "../../assets/svg/reports.svg";



function getItem(label, key, icon, children, type,path) {
    return {
        key,
        icon,
        children,
        label,
        type,
        path
    };
}



const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
	const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle, theme } = props;
	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
	const closeMobileNav = () => {
		if (isMobile) {
			onMobileNavToggle(false)
		}
	}


   const getImage = (name) => {

        if(name === 'propertyImage'){
            return propertyImage;
        }

       if(name === 'maintenance'){
           return maintenance;
       }
       if(name === 'home'){
           return home;
       }
       if(name === 'barn'){
           return barn;
       }
       if(name === 'users'){
           return users;
       }
       if (name === 'org') {
        return org;
       }
       if (name === 'facilities') {
        return facilities;
       }
       if (name === 'bell') {
        return bell;
       }
       if (name === 'reports') {
        return reports;
       }


   }

   const getHareesItems = () => {

       const items =  []
       navigationConfig.map((menu)=>{
           if(menu.submenu.length){

               let subMenuItems = [];

               menu.submenu.map((sub)=>{
                   subMenuItems.push(getItem(sub.title,sub.key,null,null,null,sub.path));
               })

               items.push(getItem(menu.title, menu.key, menu.isImage ? <img
                   style={{
                       width:14
                   }}
                   src={getImage(menu.icon)}/>   :  <Icon type={menu.icon} />, subMenuItems,null,menu.path));


           }
           else {
               items.push(getItem(menu.title, menu.key, menu.isImage ? <img
                   style={{
                       width:14
                   }}
                   src={getImage(menu.icon)}/> : <Icon type={menu.icon}/>,null,null,menu.path));
           }
       });


       return  items;

   }


  return (
    <Menu
      theme={!theme ? 'light' : theme}
      mode="inline"
      style={{ height: "100%", borderRight: 0, marginTop: 48 }}
      selectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      items={getHareesItems()}
      className={hideGroupTitle ? "app-dashboard hide-group-title" : "app-dashboard"}
      onSelect={(props)=>{

          window.location.href =props.item.props.path;

      }}
    >
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu className={'app-dashboard'} mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
