import {
  CATEGORY_REQUEST,
  CATEGORY_SUCCESS,
  CATEGORY_FAIL,
  CATEGORY_CLEAR_ERRORS,
} from "../constants/Category";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CATEGORY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CATEGORY_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
