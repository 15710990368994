
export const ALL_PROJECT_REQUEST = 'ALL_PROJECT_REQUEST'
export const ALL_PROJECT_SUCCESS = 'ALL_PROJECT_SUCCESS'
export const ALL_PROJECT_FAIL = 'ALL_PROJECT_FAIL'


export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL'    

export const GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS'    
export const GET_PROJECT_DETAILS_SUCCESS = 'GET_PROJECT_DETAILS_SUCCESS'    
export const GET_PROJECT_DETAILS_FAILURE = 'GET_PROJECT_DETAILS_FAILURE'   

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

