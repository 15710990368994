import {
  LOADING_LOOKUPS,
  SUCCESS_LOOKUPS,
  ERROR_LOOKUPS
} from '../constants/Lookups';


const initialState = {
  loadingLookups: false,
  errorLookups: false,
  lookupsData: {},
};
export  const allLookupsReducer =  (state = initialState, action) => {
  switch (action.type) {
    case LOADING_LOOKUPS:
      return { ...initialState, loadingLookups: true };
    case SUCCESS_LOOKUPS:
      return { ...initialState, lookupsData: action.payload };
    case ERROR_LOOKUPS:
      return { ...initialState, errorLookups: true };
    default:
      return state;
  }
};
