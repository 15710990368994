export const ALL_SERVICES_REQUEST = 'ALL_SERVICES_REQUEST'
export const ALL_SERVICES_SUCCESS = 'ALL_SERVICES_SUCCESS'
export const ALL_SERVICES_FAIL = 'ALL_SERVICES_FAIL'

export const CREATE_SERVICE_REQUEST = 'CREATE_SERVICE_REQUEST'
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS'
export const CREATE_SERVICE_RESET = 'CREATE_SERVICE_RESET'
export const CREATE_SERVICE_FAIL = 'CREATE_SERVICE_FAIL'

export const UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST'
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS'
export const UPDATE_SERVICE_RESET = 'UPDATE_SERVICE_RESET'
export const UPDATE_SERVICE_FAIL = 'UPDATE_SERVICE_FAIL'

export const DELETE_SERVICE_REQUEST = 'DELETE_SERVICE_REQUEST'
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS'
export const DELETE_SERVICE_RESET = 'DELETE_SERVICE_RESET'
export const DELETE_SERVICE_FAIL = 'DELETE_SERVICE_FAIL'

export const SERVICE_DETAILS_REQUEST = 'SERVICE_DETAILS_REQUEST'
export const SERVICE_DETAILS_SUCCESS = 'SERVICE_DETAILS_SUCCESS'
export const SERVICE_DETAILS_FAIL = 'SERVICE_DETAILS_FAIL'
export const SERVICE_DETAILS_RESET = 'SERVICE_DETAILS_RESET'

export const SERVICES_SUMMARY_REQUEST = 'SERVICES_SUMMARY_REQUEST';
export const SERVICES_SUMMARY_SUCCESS = 'SERVICES_SUMMARY_SUCCESS';
export const SERVICES_SUMMARY_FAIL = 'SERVICES_SUMMARY_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS'