export const ALL_TEAMS_REQUEST = 'ALL_TEAMS_REQUEST'
export const ALL_TEAMS_SUCCESS = 'ALL_TEAMS_SUCCESS'
export const ALL_TEAMS_FAIL = 'ALL_TEAMS_FAIL'

export const CREATE_TEAM_REQUEST = 'CREATE_TEAM_REQUEST'
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS'
export const CREATE_TEAM_RESET = 'CREATE_TEAM_RESET'
export const CREATE_TEAM_FAIL = 'CREATE_TEAM_FAIL'

export const UPDATE_TEAM_REQUEST = 'UPDATE_TEAM_REQUEST'
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS'
export const UPDATE_TEAM_RESET = 'UPDATE_TEAM_RESET'
export const UPDATE_TEAM_FAIL = 'UPDATE_TEAM_FAIL'

export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST'
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS'
export const DELETE_TEAM_RESET = 'DELETE_TEAM_RESET'
export const DELETE_TEAM_FAIL = 'DELETE_TEAM_FAIL'

export const TEAM_DETAILS_REQUEST = 'TEAM_DETAILS_REQUEST'
export const TEAM_DETAILS_SUCCESS = 'TEAM_DETAILS_SUCCESS'
export const TEAM_DETAILS_FAIL = 'TEAM_DETAILS_FAIL'
export const TEAM_DETAILS_RESET = 'TEAM_DETAILS_RESET'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'