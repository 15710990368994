import {
  EnvironmentOutlined,
  ApiOutlined,
  UsergroupAddOutlined,
  ToolOutlined,
  TeamOutlined,
  RocketOutlined,
  PartitionOutlined,
  SettingOutlined,
  HomeOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

import APP_ROUTES from 'constants/routes';

const dashBoardNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'Home',
    icon: 'home',
    isImage:true,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'org',
    path: `${APP_PREFIX_PATH}/org`,
    title: 'Organization',
    icon: 'org',
    isImage: true,
    breadcrumb: true,
    submenu: [
      {
        key: 'quick-add',
        path: `${APP_PREFIX_PATH}/org/quick-add`,
        title: 'Quick Add',
        isImage: false,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'areas',
        path: APP_ROUTES.ORG_AREAS_LIST,
        title: 'Areas',
        isImage: false,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'services',
        path: APP_ROUTES.ORG_SERVICES_LIST,
        title: 'Services',
        isImage: false,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'team',
        path: APP_ROUTES.ORG_TEAMS_LIST,
        title: 'Team',
        isImage: false,
        breadcrumb: false,
        submenu: []
      }
    ]
  },
  {
    key: 'users',
    title: 'Users',
    icon: 'users',
    isImage: true,
    breadcrumb: false,
    submenu: [
      {
        key: 'supervisors',
        path: APP_ROUTES.USERS_SUPERVISORS_LIST,
        title: 'Supervisors',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'tenants',
        path: APP_ROUTES.USERS_TENANTS_LIST,
        title: 'Tenant / Requester',
        icon: UsergroupAddOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'technician',
        path: APP_ROUTES.USERS_TECHNICIANS_LIST,
        title: 'Technicians',
        icon: ToolOutlined,
        breadcrumb: false,
        submenu: []
      },
    ]
  },
  {
    key: 'maintenance',
    path: `${APP_PREFIX_PATH}/maintenance`,
    title: 'Maintenance',
    icon: 'maintenance',
    isImage:true,
    breadcrumb: false,
    submenu: [{
      key: 'corrective',
      path: `${APP_PREFIX_PATH}/maintenance/corrective`,
      title: 'Corrective Maintenance',
      icon: HomeOutlined,
      breadcrumb: false,
      submenu: []
    },{
      key: 'preventive',
      path: `${APP_PREFIX_PATH}/maintenance/preventive`,
      title: 'Preventive Maintenance',
      icon: HomeOutlined,
      breadcrumb: false.submenu,
      submenu: []
    }]
  },
  {
    key: 'projects',
    path: `${APP_PREFIX_PATH}/property`,
    title: 'Projects',
    icon: 'propertyImage',
    isImage:true,
    breadcrumb: false,
    submenu: [
      {
        key: 'list',
        path: `${APP_PREFIX_PATH}/projects/list`,
        title: 'Projects',
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: []
      },{
        key: 'property-list',
        path: `${APP_PREFIX_PATH}/projects/property/list`,
        title: 'Properties',
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'unit',
        path: `${APP_PREFIX_PATH}/projects/unit/list`,
        title: 'Units',
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: []
      }
    ]
  },
  // {
  //   key: 'property',
  //   path: `${APP_PREFIX_PATH}/property`,
  //   title: 'Properties',
  //   icon: 'propertyImage',
  //   isImage:true,
  //   breadcrumb: false,
  //   submenu: [
  //     {
  //       key: 'dashboard',
  //       path: `${APP_PREFIX_PATH}/property/dashboard`,
  //       title: 'Dashboard',
  //       icon: HomeOutlined,
  //       breadcrumb: false,
  //       submenu: []
  //     },{
  //       key: 'list',
  //       path: `${APP_PREFIX_PATH}/property/list`,
  //       title: 'List of Properties',
  //       icon: HomeOutlined,
  //       breadcrumb: false,
  //       submenu: []
  //     },
  //     {
  //       key: 'unit',
  //       path: `${APP_PREFIX_PATH}/property/unit/list`,
  //       title: 'List of Units',
  //       icon: HomeOutlined,
  //       breadcrumb: false,
  //       submenu: []
  //     }
  //   ]
  // },
  {
    key: 'facilities',
    path: `${APP_PREFIX_PATH}/`,
    title: 'Facilities',
    isImage: true,
    icon: 'facilities',
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'inventories',
    path: `${APP_PREFIX_PATH}/inventories`,
    title: 'Inventories',
    icon: 'barn',
    isImage: true,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'reports',
    path: `${APP_PREFIX_PATH}/`,
    title: 'Reports',
    icon: 'reports',
    isImage: true,
    breadcrumb: false,
    submenu: []
  },
  // {
  //   key: 'areas',
  //   path: `${APP_PREFIX_PATH}/areas`,
  //   title: 'Areas',
  //   icon: EnvironmentOutlined,
  //   breadcrumb: false,
  //   submenu: []
  // },
  // {
  //   key: 'services',
  //   path: `${APP_PREFIX_PATH}/services`,
  //   title: 'Services',
  //   icon: ApiOutlined,
  //   breadcrumb: false,
  //   submenu: []
  // },
  {
    key: 'notifications',
    path: `${APP_PREFIX_PATH}/`,
    title: 'Notifications',
    isImage: true,
    icon: 'bell',
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'settings',
    path: `${APP_PREFIX_PATH}/settings`,
    title: 'Settings',
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: []
  },

]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
