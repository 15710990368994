export const ALL_TENANTS_REQUEST = 'ALL_TENANTS_REQUEST'
export const ALL_TENANTS_SUCCESS = 'ALL_TENANTS_SUCCESS'
export const ALL_TENANTS_FAIL = 'ALL_TENANTS_FAIL'

export const CREATE_TENANT_REQUEST = 'CREATE_TENANT_REQUEST'
export const CREATE_TENANT_SUCCESS = 'CREATE_TENANT_SUCCESS'
export const CREATE_TENANT_RESET = 'CREATE_TENANT_RESET'
export const CREATE_TENANT_FAIL = 'CREATE_TENANT_FAIL'

export const UPDATE_TENANT_REQUEST = 'UPDATE_TENANT_REQUEST'
export const UPDATE_TENANT_SUCCESS = 'UPDATE_TENANT_SUCCESS'
export const UPDATE_TENANT_RESET = 'UPDATE_TENANT_RESET'
export const UPDATE_TENANT_FAIL = 'UPDATE_TENANT_FAIL'

export const DELETE_TENANT_REQUEST = 'DELETE_TENANT_REQUEST'
export const DELETE_TENANT_SUCCESS = 'DELETE_TENANT_SUCCESS'
export const DELETE_TENANT_RESET = 'DELETE_TENANT_RESET'
export const DELETE_TENANT_FAIL = 'DELETE_TENANT_FAIL'

export const TENANT_DETAILS_REQUEST = 'TENANT_DETAILS_REQUEST'
export const TENANT_DETAILS_RESET = 'TENANT_DETAILS_RESET'
export const TENANT_DETAILS_SUCCESS = 'TENANT_DETAILS_SUCCESS'
export const TENANT_DETAILS_FAIL = 'TENANT_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'