import React, {useState} from "react";
import {Avatar, Col, Divider, Layout, Row, Segmented} from 'antd';
import { connect } from 'react-redux';
import { SIDE_NAV_WIDTH, SIDE_NAV_DARK, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';
import MenuContent from './MenuContent'
import moon from "../../assets/svg/moon.svg";
import sun from "../../assets/images/sun.png";

import {
  InfoCircleOutlined

} from '@ant-design/icons';


const { Sider } = Layout;

export const SideNav = ({navCollapsed, sideNavTheme, routeInfo, hideGroupTitle, localization = true }) => {
  const props = { sideNavTheme, routeInfo , hideGroupTitle, localization}
  const [theme,setTheme] = useState('light');

  return (
    <Sider
      className={`side-nav ${sideNavTheme === SIDE_NAV_DARK? 'side-nav-dark' : ''}`}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}
    >
      <Scrollbars autoHide>
        <MenuContent
          theme={theme}
          type={NAV_TYPE_SIDE}
          {...props}
        />
        <div style={{
          position:"absolute",
          left: 0,
          right: 0,
          bottom: -10,
          display: 'none'
        }}>
          <Divider/>
          <Row align={"middle"} style={{
            paddingLeft: 16
          }}>
            <Col>
              <InfoCircleOutlined/>
            </Col>
            <Col className={"ml-2"}>
              <p>Help & getting Started</p>
            </Col>
          </Row>

          <Segmented
              onChange={(e)=>{
                  console.log("val",e);
                  setTheme(e);
              }}
              className={'sidebar-segment'}
              options={[
                {
                  label: (
                      <div className={"d-flex"}>
                          <img src={moon}/>
                          <div>Light</div>
                      </div>
                  ),
                  value: 'light',
                },
                  {
                      label: (
                          <div className={"d-flex"}>
                              <img src={sun} />
                              <div>Dark</div>
                          </div>
                      ),
                      value: 'dark',
                  },
              ]}
          />

        </div>

      </Scrollbars>
    </Sider>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme } =  theme;
  return { navCollapsed, sideNavTheme }
};

export default connect(mapStateToProps)(SideNav);
