import {
    ALL_MAINTENANCE_REQUEST,
    ALL_MAINTENANCE_SUCCESS,
    ALL_MAINTENANCE_FAIL,
    CLEAR_ERRORS,
    CREATE_CORRECTIVE_MAINTENANCE_FAIL,
    CREATE_CORRECTIVE_MAINTENANCE_SUCCESS,
    CREATE_CORRECTIVE_MAINTENANCE,
    GET_CORRECTIVE_MAINTENANCE_SUCCESS,
    GET_CORRECTIVE_MAINTENANCE,
    GET_CORRECTIVE_MAINTENANCE_FAIL,
    EDIT_CORRECTIVE_MAINTENANCE,
    EDIT_CORRECTIVE_MAINTENANCE_FAIL,
    EDIT_CORRECTIVE_MAINTENANCE_SUCCESS,
    CREATE_PREVENTIVE_MAINTENANCE,
    CREATE_PREVENTIVE_MAINTENANCE_SUCCESS,
    CREATE_PREVENTIVE_MAINTENANCE_FAIL,
    GET_PREVENTIVE_MAINTENANCE,
    GET_PREVENTIVE_MAINTENANCE_SUCCESS,
    GET_PREVENTIVE_MAINTENANCE_FAIL,
    EDIT_PREVENTIVE_MAINTENANCE,
    EDIT_PREVENTIVE_MAINTENANCE_FAIL,
    EDIT_PREVENTIVE_MAINTENANCE_SUCCESS
} from '../constants/Maintenance'


export const allMaintenanceReducer = (state = {
    loading: false,
    maintenance: [],
    loadingCreate: false,
    isCreated: false,
    errorCreate: false,
    data: null,
    loadingGetMaintenance: false,
    maintenanceData: null,
    errorGetMaintenance: false,
    loadingEdit: false,
    errorEdit: false,
    isUpdated: false,
}, action) => {
    switch (action.type) {


        case CREATE_CORRECTIVE_MAINTENANCE:
            return {
                ...state,
                loadingCreate: true,
                isCreated: false
            }

        case CREATE_CORRECTIVE_MAINTENANCE_SUCCESS:
            return {
                loadingCreate: false,
                data: action.payload,
                isCreated: true
            }


        case CREATE_CORRECTIVE_MAINTENANCE_FAIL:
            return {
                loadingCreate: false,
                errorCreate: action.payload,
                isCreated: false
            }


        case CREATE_PREVENTIVE_MAINTENANCE:
            return {
                ...state,
                loadingCreate: true,
                isCreated: false
            }

        case CREATE_PREVENTIVE_MAINTENANCE_SUCCESS:
            return {
                loadingCreate: false,
                data: action.payload,
                isCreated: true
            }


        case CREATE_PREVENTIVE_MAINTENANCE_FAIL:
            return {
                loadingCreate: false,
                errorCreate: action.payload,
                isCreated: false
            }


        case EDIT_CORRECTIVE_MAINTENANCE:
            return {
                ...state,
                loadingEdit: true,
                isUpdated: false,
            }

        case EDIT_CORRECTIVE_MAINTENANCE_SUCCESS:
            return {
                loadingEdit: false,
                isUpdated: true,
                data: action.payload
            }


        case EDIT_CORRECTIVE_MAINTENANCE_FAIL:
            return {
                loadingEdit: false,
                isUpdated: false,
                errorEdit: action.payload
            }



        case EDIT_PREVENTIVE_MAINTENANCE:
            return {
                ...state,
                loadingEdit: true,
                isUpdated: false,
            }

        case EDIT_PREVENTIVE_MAINTENANCE_SUCCESS:
            return {
                loadingEdit: false,
                isUpdated: true,
                data: action.payload
            }


        case EDIT_PREVENTIVE_MAINTENANCE_FAIL:
            return {
                loadingEdit: false,
                isUpdated: false,
                errorEdit: action.payload
            }



        case ALL_MAINTENANCE_REQUEST:
            return {
                loading: true
            }

        case ALL_MAINTENANCE_SUCCESS:
            return {
                loading: false,
                maintenance: action.payload.items,
                reportingItems: action.payload.reportingItems ?? [],
                page: action.payload.page,
                totalPages: action.payload.totalPages,
                itemsTotalCount: action.payload.itemsTotalCount
            }

        case ALL_MAINTENANCE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case GET_CORRECTIVE_MAINTENANCE: {
            return {
                loadingGetMaintenance: true
            }
        }

        case GET_CORRECTIVE_MAINTENANCE_SUCCESS: {
            return {
                loadingGetMaintenance: false,
                maintenanceData: action.payload

            }
        }

        case GET_CORRECTIVE_MAINTENANCE_FAIL: {
            return {
                loadingGetMaintenance: false,
                error: action.payload

            }
        }

        case GET_PREVENTIVE_MAINTENANCE: {
            return {
                loadingGetMaintenance: true
            }
        }

        case GET_PREVENTIVE_MAINTENANCE_SUCCESS: {
            return {
                loadingGetMaintenance: false,
                maintenanceData: action.payload

            }
        }

        case GET_PREVENTIVE_MAINTENANCE_FAIL: {
            return {
                loadingGetMaintenance: false,
                error: action.payload

            }
        }



        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}
