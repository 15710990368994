import { GET_PROJECT_DETAILS, GET_PROJECT_DETAILS_SUCCESS, GET_PROJECT_DETAILS_FAILURE } from '../constants/Project';

const initialState = {
  loading: false,
  projectDetails: null,
  error: null
};

const project = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_DETAILS:
      return {
        ...state,
        loading: true
      };
    case GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        projectDetails: action.payload,
        error: null
      };
    case GET_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default project;
