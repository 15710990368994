import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';

export const ClientViews = () => {

    return (
        <Suspense fallback={<Loading cover="page"/>}>
            <Switch>
                <Route path={`/`} component={lazy(() => import(`./create/index`))} />
            </Switch>
        </Suspense>
    )
}

export default ClientViews;

