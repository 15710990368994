export const PROFILE_DATA_REQUEST = 'PROFILE_DATA_REQUEST'
export const PROFILE_DATA_SUCCESS = 'PROFILE_DATA_SUCCESS'
export const PROFILE_DATA_FAIL = 'PROFILE_DATA_FAIL'

export const UPDATE_ME_REQUEST = 'UPDATE_ME_REQUEST'
export const UPDATE_ME_SUCCESS = 'UPDATE_ME_SUCCESS'
export const UPDATE_ME_RESET = 'UPDATE_ME_RESET'
export const UPDATE_ME_FAIL = 'UPDATE_ME_FAIL'


export const UPDATE_OTP = 'UPDATE_OTP_REQUEST'
export const UPDATE_OTP_SUCCESS = 'UPDATE_OTP_SUCCESS'
export const UPDATE_OTP_FAIL = 'UPDATE_OTP_FAIL'
export const UPDATE_OTP_RESET = 'UPDATE_OTP_RESET'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
