import {
  CURRENCY_REQUEST,
  CURRENCY_SUCCESS,
  CURRENCY_FAIL,
  CURRENCY_CLEAR_ERRORS,
} from "../constants/Currency";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case CURRENCY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CURRENCY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case CURRENCY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CURRENCY_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
