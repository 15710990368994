import {
  SUPPLIER_REQUEST,
  SUPPLIER_SUCCESS,
  SUPPLIER_FAIL,
  SUPPLIER_RESET,
  SUPPLIER_CLEAR_ERRORS,
} from "redux/constants/Supplier";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPLIER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case SUPPLIER_RESET:
      return {
        ...state,
        loading: false,
        data: null,
      };

    case SUPPLIER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SUPPLIER_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
